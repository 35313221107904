<template>
	<main>
		<div class="el-content">
			<div class="info">
				<div class="p">您好，<i>{{admin_info.nickname}}</i></div>
				<div class="p">现在是：{{time}}</div>
				<div class="p">当前可用额度：<el-tag class="tag" type="primary">通用类短信余额：{{sms_balance.balance || 0}}</el-tag><el-tag class="tag" type="primary">邮件余额：{{email_balance.balance || 0}}</el-tag></div>
			</div>
			<div class="stat-box">
				<div class="item user" id="user"></div>
			</div>
		</div>
	</main>
</template>

<script >
import moment from "moment"
import ComponentsDistrict from "@/components/admin/form/District"
import {dashboard} from "@/api/admin";

import * as echarts from 'echarts/core';
import {
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent
} from 'echarts/components';
import { PieChart,LineChart,BarChart} from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent,
	PieChart,
	LineChart,
	BarChart,
	CanvasRenderer,
	LabelLayout
]);

export default {
	components:{ComponentsDistrict},
	data(){
		return {
			timer:"",
			time:"",
			sms_balance:{},
			email_balance:{},
			user:[],
		}
	},
	computed:{
		admin_info(){
			return this.$store.state.admin.admin_info
		}
	},
	mounted() {
		this.createTime();
		this.getBalance();
		this.getUser();
	},
	methods:{
		getBalance(){
			dashboard.balance().then((res)=>{
				this.sms_balance = res.sms;
				this.email_balance = res.email;
			})
		},
		getUser(){
			dashboard.user().then((res)=>{
				this.user = res.user;
				this.createUser();
			})
		},
		createUser(){
			const chartDom = document.getElementById('user');
			const myChart = echarts.init(chartDom);

			const data = [];
			const count = [];
			const add = [];
			this.user.map((item,index)=>{
				data.push(item.date);
				count.push(item.count);
				add.push(item.add);
			})

			const option = {
				title: {
					text: '用户增长趋势'
				},
				tooltip: {
					trigger: 'axis'
				},
				toolbox: {
					feature: {
						saveAsImage: { show: true }
					}
				},
				legend: {
					top:"bottom",
				},
				grid: {
					top: '10%',
					left: '3%',
					right: '3%',
					bottom: '10%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data:data
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: '用户总数',
						type: 'line',
						data: count,
					},
					{
						name: '新增数',
						type: 'line',
						data: add,
					},
				]
			};

			option && myChart.setOption(option);
			window.addEventListener('resize',()=>{
				myChart.resize();
			})
		},
		createTime(){
			const step = ()=>{
				this.time = moment().format('YYYY年MM月DD日 HH:mm:ss')
			}
			step();

			clearInterval(this.timer);
			this.timer = setInterval(()=>{
				step()
			},1000);

			this.$once('beforeDestroy',()=>{
				clearInterval(this.timer);
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.info{font-size: 16px;line-height: 30px;
	i{font-weight: bold;}
	.tag{margin-right: 7px;}
}
.stat-box{margin-top: 30px;
	.item{height:400px;margin-bottom: 30px;}
}
</style>
